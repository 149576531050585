import { LangGraphEndpointType } from '../types/langGraph';

export const getEndpointTypeFromUrl = (url: string): LangGraphEndpointType | undefined => {
  const regEx = /\/.*\/(wait|stream)$/g;
  const match = regEx.exec(url);
  if (!match || match.length < 2) {
    return undefined;
  }

  const extractedEndpoint = match[1];
  if (extractedEndpoint === 'wait' || extractedEndpoint === 'stream') {
    return extractedEndpoint;
  }

  return undefined;
};
