import { debug } from '../utils/debug';
import { warn } from '../utils/warn';

export const parseStreamedEvent = (
  event: string
):
  | {
      event: 'messages' | 'end';
      data: unknown;
    }
  | undefined => {
  const regEx = /^event:\s+(?<event>[\w]+)((\r?)\n(\r?)data: (?<data>(.|\n)*))?/gm;
  const match = regEx.exec(event);
  console.log(match);
  if (!match) {
    return undefined;
  }

  const { event: eventName, data: rawData } = match.groups || {};
  if (!eventName) {
    return undefined;
  }

  if (eventName !== 'messages' && eventName !== 'end') {
    debug(`LangGraph stream adapter received unsupported event "${eventName}"`);
    return undefined;
  }

  try {
    const data = rawData ? JSON.parse(rawData) : undefined;
    return { event: eventName, data };
  } catch (_error) {
    warn(
      `LangGraph stream adapter failed to parse data for chunk event "${eventName}" | Data: ${rawData}`
    );
    return { event: eventName, data: undefined };
  }
};
