import { DataTransferMode } from '@nlux/core';
import { NluxUsageError } from '../types/error';
import { LangGraphAbstractAdapter } from '../adapter/adapter';
import { LangGraphBatchAdapter } from '../adapter/batch';
import { LangGraphStreamAdapter } from '../adapter/stream';
import { ChatAdapterOptions } from '../types/adapterOptions';
import { LangGraphInputPreProcessor } from '../types/inputPreProcessor';
import { LangGraphConfig, LangGraphHeaders } from '../types/langGraph';
import { LangGraphOutputPreProcessor } from '../types/outputPreProcessor';
import { getDataTransferModeToUse } from '../utils/getDataTransferModeToUse';
import { ChatAdapterBuilder } from './builder';

export class LangGraphAdapterBuilderImpl<AiMsg> implements ChatAdapterBuilder<AiMsg> {
  private theConfig?: LangGraphConfig;
  private theDataTransferMode?: DataTransferMode;
  private theHeaders?: LangGraphHeaders;
  private theInputPreProcessor?: LangGraphInputPreProcessor<AiMsg>;
  private theOutputPreProcessor?: LangGraphOutputPreProcessor<AiMsg>;
  private theUrl?: string;
  private theUseInputSchema?: boolean;

  constructor(cloneFrom?: LangGraphAdapterBuilderImpl<AiMsg>) {
    if (cloneFrom) {
      this.theDataTransferMode = cloneFrom.theDataTransferMode;
      this.theHeaders = cloneFrom.theHeaders;
      this.theConfig = cloneFrom.theConfig;
      this.theInputPreProcessor = cloneFrom.theInputPreProcessor;
      this.theOutputPreProcessor = cloneFrom.theOutputPreProcessor;
      this.theUrl = cloneFrom.theUrl;
    }
  }

  create(): LangGraphAbstractAdapter<AiMsg> {
    if (!this.theUrl) {
      throw new NluxUsageError({
        source: this.constructor.name,
        message:
          'Unable to create LangGraph adapter. URL is missing. ' +
          'Make sure you are calling withUrl() before calling create().',
      });
    }

    const options: ChatAdapterOptions<AiMsg> = {
      url: this.theUrl,
      dataTransferMode: this.theDataTransferMode,
      headers: this.theHeaders,
      config: this.theConfig,
      inputPreProcessor: this.theInputPreProcessor,
      outputPreProcessor: this.theOutputPreProcessor,
      useInputSchema: this.theUseInputSchema,
    };

    const dataTransferModeToUse = getDataTransferModeToUse(options);
    if (dataTransferModeToUse === 'stream') {
      return new LangGraphStreamAdapter<AiMsg>(options);
    }

    return new LangGraphBatchAdapter(options);
  }

  withConfig(LangGraphConfig: LangGraphConfig): ChatAdapterBuilder<AiMsg> {
    if (this.theConfig !== undefined) {
      throw new NluxUsageError({
        source: this.constructor.name,
        message: 'Cannot set the config option more than once',
      });
    }

    this.theConfig = LangGraphConfig;
    return this;
  }

  withDataTransferMode(mode: DataTransferMode): LangGraphAdapterBuilderImpl<AiMsg> {
    if (this.theDataTransferMode !== undefined) {
      throw new NluxUsageError({
        source: this.constructor.name,
        message: 'Cannot set the data loading mode more than once',
      });
    }

    this.theDataTransferMode = mode;
    return this;
  }

  withHeaders(headers: LangGraphHeaders): ChatAdapterBuilder<AiMsg> {
    if (this.theHeaders !== undefined) {
      throw new NluxUsageError({
        source: this.constructor.name,
        message: 'Cannot set the headers option more than once',
      });
    }

    this.theHeaders = headers;
    return this;
  }

  withInputPreProcessor(
    inputPreProcessor: LangGraphInputPreProcessor<AiMsg>
  ): ChatAdapterBuilder<AiMsg> {
    if (this.theInputPreProcessor !== undefined) {
      throw new NluxUsageError({
        source: this.constructor.name,
        message: 'Cannot set the input pre-processor option more than once',
      });
    }

    this.theInputPreProcessor = inputPreProcessor;
    return this;
  }

  withInputSchema(useInputSchema: boolean): ChatAdapterBuilder<AiMsg> {
    if (this.theUseInputSchema !== undefined) {
      throw new NluxUsageError({
        source: this.constructor.name,
        message: 'Cannot set the input schema option more than once',
      });
    }

    this.theUseInputSchema = useInputSchema;
    return this;
  }

  withOutputPreProcessor(
    outputPreProcessor: LangGraphOutputPreProcessor<AiMsg>
  ): ChatAdapterBuilder<AiMsg> {
    if (this.theOutputPreProcessor !== undefined) {
      throw new NluxUsageError({
        source: this.constructor.name,
        message: 'Cannot set the output pre-processor option more than once',
      });
    }

    this.theOutputPreProcessor = outputPreProcessor;
    return this;
  }

  withUrl(runnableUrl: string): ChatAdapterBuilder<AiMsg> {
    if (this.theUrl !== undefined) {
      throw new NluxUsageError({
        source: this.constructor.name,
        message: 'Cannot set the runnable URL option more than once',
      });
    }

    this.theUrl = runnableUrl;
    return this;
  }
}
