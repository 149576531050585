import { NLErrorId } from '../types/exceptions/errors';

export const adapterErrorToExceptionId = (error: unknown): NLErrorId | null => {
  if (typeof error === 'object' && error !== null) {
    const errorAsObject = error as Record<string, unknown>;
    if (
      errorAsObject.message &&
      typeof errorAsObject.message === 'string' &&
      errorAsObject.message.toLowerCase().includes('connection error')
    ) {
      return 'connection-error';
    }
  }

  return null;
};
