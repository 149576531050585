import { ChatAdapterOptions } from '../types/adapterOptions';
import { LangGraphEndpointType } from '../types/langGraph';
import { getDataTransferModeToUse } from './getDataTransferModeToUse';
import { getEndpointTypeFromUrl } from './getEndpointTypeFromUrl';

export const getEndpointTypeToUse = <AiMsg>(
  adapterOptions: ChatAdapterOptions<AiMsg>
): LangGraphEndpointType => {
  const urlFromOptions = adapterOptions.url;
  const actionFromUrl = getEndpointTypeFromUrl(urlFromOptions);
  if (actionFromUrl) {
    return actionFromUrl;
  }

  const dataTransferMode = getDataTransferModeToUse(adapterOptions);
  return dataTransferMode === 'batch' ? 'wait' : 'stream';
};
