import { DataTransferMode } from '@nlux/core';
import { LangGraphEndpointType } from '../types/langGraph';

export const getDataTransferModeFromEndpointType = (
  endpointAction: LangGraphEndpointType
): DataTransferMode => {
  if (endpointAction === 'stream') {
    return 'stream';
  }

  return 'batch';
};
